import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  SEND_NEW_CHAT_MESSAGE,
  SEND_NEW_MEDIA_MESSAGE,
  SET_CHAT_USERS,
  SET_CONTACT_USERS,
  SET_CONVERSATION_DATA,
  SET_CURRENT_USER,
  SEND_MESSAGE,
  READ_CONVERSATION,
  SET_CHAT_LOADER,
  SET_PER_PAGE,
  SET_MORE_TO_LOAD,
  SET_MSG_PER_PAGE,
  SET_MSG_MORE_TO_LOAD,
  SET_CHAT_USER_LOADING
} from '../../@jumbo/constants/ActionTypes';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import sweetAlerts from '../../appmakers/components/SweetAlertHelper';

export const getChatUsers = (userId, perPage, loading = true) => {
  return dispatch => {
    if (loading)
      dispatch(fetchStart());

    http.get(`${rootUrl}/conversations`, { params: { userId, perPage, isApk: false } }).then(data => {
      if (data.status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: SET_CHAT_USERS,
          payload: data.data.conversations,
        });
        dispatch({
          type: SET_CHAT_USER_LOADING,
          payload: false,
        });
        dispatch({
          type: SET_MORE_TO_LOAD,
          payload: data.data.moreToLoad,
        });
      } else {
        dispatch(fetchError('Something went wrong'));
      }
    });
  };
};

export const setMoreToLoad = payload => {
  return dispatch => {
    dispatch({
      type: SET_MORE_TO_LOAD,
      payload: payload,
    });
  };
};
export const setLoading = payload => {
  return dispatch => {
    dispatch({
      type: SET_CHAT_USER_LOADING,
      payload: payload,
    });
  };
};
export const setPerPage = payload => {
  return dispatch => {
    dispatch({
      type: SET_PER_PAGE,
      payload: payload,
    });
  };
};

export const setMsgMoreToLoad = payload => {
  return dispatch => {
    dispatch({
      type: SET_MSG_MORE_TO_LOAD,
      payload: payload,
    });
  };
};
export const setMsgPerPage = payload => {
  return dispatch => {
    dispatch({
      type: SET_MSG_PER_PAGE,
      payload: payload,
    });
  };
};


export const sendMessage = (message, conversationId) => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/send-message`, { conversationId, message })
      .then(data => {
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};
export const readConversation = (conversationId, perPage) => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/seen-conversation`, { conversationId, perPage })
      .then(res => {
        dispatch({
          type: READ_CONVERSATION,
          payload: { conversationId, messages: res.data },
        });
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const updateReadConversation = ({ conversationId, messages }) => {
  return dispatch => {
    dispatch({
      type: READ_CONVERSATION,
      payload: { conversationId, messages },
    });
  };
};

export const getContactUsers = (filterData = { search: '' }) => {
  return dispatch => {
    dispatch(fetchStart());
    axios.get(`${rootUrl}/chat/contact/users`, { params: { filterData: filterData } }).then(data => {
      if (data.status === 200) {
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError('Something went wrong'));
      }
    });
  };
};

export const getConversation = (conversationId, perPage) => {
  //debugger
  return dispatch => {
    dispatch(fetchStart());
    http.get(`${rootUrl}/conversation`, { params: { conversationId, perPage } }).then(data => {
      //debugger;
      if (data.status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: SET_CONVERSATION_DATA,
          payload: data.data.messages,
        });
        dispatch({
          type: SET_MSG_MORE_TO_LOAD,
          payload: data.data.moreToLoad,
        });
      } else {
        dispatch(fetchError('Something went wrong'));
      }
    });
  };
};

export const onUserSelect = user => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: user,
    });
  };
};

export const sendTextMessage = message => {
  return dispatch => {
    dispatch({
      type: SEND_NEW_CHAT_MESSAGE,
      payload: message,
    });
  };
};


// export const sendMediaMessage = file => {
//     return dispatch => {
//         dispatch({
//             type: SEND_NEW_MEDIA_MESSAGE,
//             payload: file,
//         });
//     };
// };

export const sendMediaMessage = (form) => {
  return dispatch => {
    dispatch(fetchStart());
    // console.log("sendMediaMessage=", form)
    http.post(`${rootUrl}/send-media-message`, form).then(data => {
      dispatch(fetchSuccess());
    }).catch(error => {
      dispatch(fetchError('Something went wrong'));
      if (error.data.message) {
        sweetAlerts(error.data.message, 'error')
      } else {
        sweetAlerts(error.data, 'error')
      }
    });
  };
};

export const onSetLoader = (value, length, id) => {
  return dispatch => {
    dispatch({
      type: SET_CHAT_LOADER,
      payload: { value, length, id }
    });
  };
};

export const onContactSelectAction = contact => {
  return dispatch => {
    dispatch(setMsgMoreToLoad(true));
    dispatch(setMsgPerPage(20));
    //dispatch(getConversation(contact._id, 20));
    dispatch(readConversation(contact._id, 20));
    dispatch(onUserSelect(contact));
  };
};