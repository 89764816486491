import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import { componentColors } from '../CmtHelpers/JssHelper';
import useStyles from './index.style';
import { fontSize } from '@mui/system';
// import { Avatar } from '@material-ui/core';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
const nameInCaps = (name, fontSize) => {
  return (
    name && <span style={{ fontSize: `${fontSize}px` }}>
      <strong>{name.split(' ').length > 1 ? `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}` : `${name.split(' ')[0][0].toUpperCase()}`}</strong>
    </span>
  )

}
function stringAvatar(name, fontSize) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 100,
      height: 100,
    },
    children: nameInCaps(name, fontSize)
  };
}


const CmtAvatar = ({ src, alt, size, fontSize, variant, className, border, ...rest }) => {
  // debugger
  // return (
  //   <Avatar {...stringAvatar(alt, fontSize)} src={`${src}?${Date.now()}`} className={clsx(className && className)}
  //     variant={variant}
  //     {...rest}
  //     style={{ width: `${size}px`, height: `${size}px` }}
  //   />
  // );

  return (
    <Avatar
      className={clsx(className && className)}
      alt={nameInCaps(alt, fontSize)}
      variant={variant}
      {...rest}
      style={{
        backgroundColor: src ? '#fff' : stringToColor(alt),
        width: `${size}px`,
        height: `${size}px`,
        border: border,
        backgroundImage: `url(${src + "?" + Date.now()})`,
        backgroundSize: 'contain', // Ensures the entire image is visible
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', // No repeat for smaller images
      }}

    >
      {!src && alt && nameInCaps(alt, fontSize)}
    </Avatar>
  );
};
CmtAvatar.propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(['rounded', 'square', 'circular']),
  fontSize: PropTypes.number,
  border: PropTypes.string,
};

CmtAvatar.defaultProps = {
  size: 55,
  variant: 'circular',
  fontSize: 25,
  border: '',
};

export default CmtAvatar;
