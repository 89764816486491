import { ServerUrl, SocketIoUrl, SocketIoAutoQuoteUrl } from '../constants/DataBasedOnUrl'
var serverUrl = ServerUrl;
var socketIoUrl = SocketIoUrl;
var socketIoAutoQuoteUrl = SocketIoAutoQuoteUrl;

//console.log('Environment: ', process.env.REACT_APP_ENVIRONMENT);
if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  serverUrl = 'http://localhost:8000';
  socketIoUrl = 'http://localhost:8000';
  socketIoAutoQuoteUrl = 'http://localhost:8001';
}

export const rootUrl = serverUrl;
export const socketUrl = socketIoUrl;
export const socketAutoQuoteUrl = socketIoAutoQuoteUrl;
