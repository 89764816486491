import {
    CLOSE_PAYMENT_MODAL,
    HIDE_LOADER,
    OPEN_PAYMENT_MODAL,
    SELECT_PLAN,
    SHOW_LOADER,
    SELECTED_PLAN_INDEX,
    OPEN_NEW_CARD_MODAL,
    CLOSE_NEW_CARD_MODAL,
} from "../constants/PlanActionTypes";

const INIT_STATE = {
    plans: [
        { credits: 50, bonus: 10, price: 20, expDays: 90 },
        { credits: 100, bonus: 24, price: 50, expDays: 90 },
        { credits: 200, bonus: 50, price: 100, expDays: 90 },
        { credits: 500, bonus: 100, price: 200, expDays: 90 }
    ],
    selectedPlan: {},
    loading: false,
    openPaymentModal: false,
    openNewCardModal: false,
    selectedPlanIndex: null
};

export default (state = INIT_STATE, { type, payload }) => {
    switch (type) {
        case OPEN_PAYMENT_MODAL: {
            return { ...state, openPaymentModal: true }
        }
        case CLOSE_PAYMENT_MODAL: {
            return { ...state, openPaymentModal: false, selectedPlanIndex: null }
        }
        case OPEN_NEW_CARD_MODAL: {
            return { ...state, openNewCardModal: true }
        }
        case CLOSE_NEW_CARD_MODAL: {
            return { ...state, openNewCardModal: false, selectedPlanIndex: null }
        }
        case SHOW_LOADER: {
            return { ...state, loading: true }
        }
        case HIDE_LOADER: {
            return { ...state, loading: false }
        }
        case SELECT_PLAN: {
            return { ...state, selectedPlan: payload }
        }
        case SELECTED_PLAN_INDEX: {
            return { ...state, selectedPlanIndex: payload }
        }
        default:
            return state;
    }
};
