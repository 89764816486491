import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Chat from './Chat';
import {BusinessDirectoryReducer} from "./BusinessDirectoryReducer";
import {LeadsReducer} from "./LeadsReducer";
import {IframeReducer} from "./IframeReducer";
import PlanReducer from "./PlanReducer";
import {QuoteActivityReducer} from "./QuoteActivityReducer";
import {JobReducer} from "./JobReducer";


export default history =>
    combineReducers({
        router: connectRouter(history),
        common: Common,
        auth: Auth,
        chat: Chat,
        BusinessDirectoryReducer: BusinessDirectoryReducer,
        LeadsReducer,
        IframeReducer,
        PlanReducer,
        QuoteActivityReducer,
        JobReducer,
    });
