export const FETCH_LEADS_REQUEST = 'fetch_leads_request';
export const FETCH_LEADS_SUCCESS = 'fetch_leads_success';
export const FETCH_LEADS_FAILURE = 'fetch_leads_failure';
export const FETCH_LEAD_REQUEST = 'fetch_lead_request';
export const FETCH_LEAD_SUCCESS = 'fetch_lead_success';
export const FETCH_LEAD_FAILURE = 'fetch_lead_failure';
export const HANDLE_COST_CHECKBOX = 'handle_cost_checkbox';
export const HANDLE_INSPECTION_CHECKBOX = 'handle_inspection_checkbox';
export const HANDLE_INFORMATION_CHECKBOX = 'handle_information_checkbox';
export const HANDLE_COMMENT_CHECKBOX = 'handle_comment_checkbox';
export const CHANGE_BUDGET_TYPE = 'change_budget_type';
export const CHANGE_EST_COST = 'change_est_cost';
export const CHANGE_COMMENT = 'change_comment';
export const SEND_QUOTE = 'send_quote';
export const POST_QUOTE_REQUEST_START = 'post_quote_request_start'
export const POST_QUOTE_REQUEST_END = 'post_quote_request_end'
export const FETCH_DRAFT_LEADS_SUCCESS = 'FETCH_DRAFT_LEADS_SUCCESS'
export const FETCH_DRAFT_LEADS_FAILURE = 'FETCH_DRAFT_LEADS_FAILURE'
export const FETCH_QUOTED_LEADS_SUCCESS = 'FETCH_QUOTED_LEADS_SUCCESS'
export const FETCH_QUOTED_LEADS_FAILURE = 'FETCH_QUOTED_LEADS_FAILURE'
export const SET_INDEX = 'SET_INDEX';
export const SET_KEY = 'SET_KEY';