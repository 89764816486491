import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Typography} from "@material-ui/core";

const CmtImage = ({src, alt, color, ...rest}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: color
        }}>
            <img src={src} alt="TradingSeekLogo" style={{width: '2rem'}}/>
            <Typography variant="h3"
                        className="ml-1 text-xl font-weight-normal text-gray-900 hover:text-blue-600 cursor-pointer">{process.env.REACT_APP_APP_NAME}</Typography>
        </div>
    );
};

export default CmtImage;
