import React, { Suspense, lazy } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box, Typography, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import { Link } from 'react-router-dom';
const IntlMessages = lazy(() => import('../../../utils/IntlMessages'));

// import CmtImage from '../../../../@coremat/CmtImage';
const CmtImage = lazy(() => import('../../../../@coremat/CmtImage'));

const Logo = ({ color, ...props }) => {
  const windowSize = useWindowSize();

  const logoUrl = process.env.REACT_APP_LOGO_URL ? process.env.REACT_APP_LOGO_URL : '/images/Tradingseek.svg';
  // const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';

  return (
    <Suspense fallback={'loading...'}>
      <Box className="pointer" {...props}>
        <Hidden xsDown>
          <NavLink to="/" className="d-flex text-black">
            {/*<img src={logoUrl} alt="TradingSeekLogo" style={{width: '2rem'}}/>*/}
            <CmtImage src={logoUrl} alt="logo" color="black" />
            <img
              src={
                process.env.REACT_APP_COUNTRY !== "NET"
                  ? `https://flagcdn.com/16x12/${process.env.REACT_APP_COUNTRY.toLowerCase()}.png`
                  : '/images/beta.png'
              }
              alt="flag"
              style={{ width: '1.2rem', margin: '10px' }}
            />
          </NavLink>
        </Hidden>
        <Hidden smUp>
          {/* <NavLink to="/">
            <img src={logoUrl} alt="TradingSeekLogo" style={{ width: '2rem' }} /> */}
          {/*<CmtImage src={logoSymbolUrl} alt="logo" />*/}
          {/* </NavLink>  */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NavLink to="/" className="d-flex text-black">
              {/* <img src={logoUrl} alt="TradingSeekLogo" style={{ width: '2rem' }} /> */}
              <CmtImage src={logoUrl} alt="logo" color="black" />
              {/* <img src={`https://flagcdn.com/16x12/${process.env.REACT_APP_COUNTRY.toLowerCase()}.png`} alt="TradingSeekLogo" style={{ width: '20px', height: '20px', margin: '10px 30px' }} /> */}
              <img
                src={
                  process.env.REACT_APP_COUNTRY !== "NET"
                    ? `https://flagcdn.com/16x12/${process.env.REACT_APP_COUNTRY.toLowerCase()}.png`
                    : '/images/beta.png'
                }
                alt="flag"
                style={{ width: '20px', height: '20px', margin: '10px 30px' }}
              />
            </NavLink>
            {/* {!(localStorage.getItem('token') || sessionStorage.getItem('token')) && (
              <>
                <div style={{ display: 'inline-block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to={'/login'} style={{ marginRight: '2rem' }}>
                      <a >
                        Login
                      </a>
                    </Link>
                    <Link to={'/signup-business'} className="Cmt-btn" size="small">
                      <Button variant="contained" color="primary">
                        <IntlMessages id="sidebar.appModule.registerBusiness" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            )} */}
          </div>
        </Hidden>
      </Box>
    </Suspense>
  );
};

export default Logo;
