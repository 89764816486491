import moment from 'moment';
import {
    SEND_NEW_CHAT_MESSAGE,
    SEND_NEW_MEDIA_MESSAGE,
    SET_CHAT_USERS,
    SET_CONTACT_USERS,
    SET_CONVERSATION_DATA,
    SET_CURRENT_USER,
    SET_FILTER_DATA,
    TOGGLE_SIDEBAR_COLLAPSED,
    READ_CONVERSATION,
    SET_CHAT_LOADER,
    SET_PER_PAGE,
    SET_MORE_TO_LOAD,
    SET_MSG_PER_PAGE,
    SET_MSG_MORE_TO_LOAD,
    SET_CHAT_USER_LOADING
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    users: [],
    contacts: [],
    conversation: [],
    currentUser: null,
    isSideBarCollapsed: false,
    filterData: {
        search: '',
    },
    loaderChat: false,
    moreToLoad: true,
    loading: false,
    perPage: 0,
    msgPerPage: 20,
    msgMoreToLoad: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CHAT_USERS: {
            return { ...state, users: action.payload };
        }
        case SET_PER_PAGE: {
            return { ...state, perPage: action.payload };
        }
        case SET_MSG_PER_PAGE: {
            return { ...state, msgPerPage: action.payload };
        }
        case SET_MORE_TO_LOAD: {
            return { ...state, moreToLoad: action.payload };
        }
        case SET_MSG_MORE_TO_LOAD: {
            return { ...state, msgMoreToLoad: action.payload };
        }
        case SET_CONTACT_USERS: {
            return { ...state, contacts: action.payload };
        }
        case SET_CURRENT_USER: {
            return { ...state, currentUser: action.payload };
        }
        case SET_CONVERSATION_DATA: {
            return {
                ...state,
                conversation: action.payload,
            };
        }
        case SEND_NEW_CHAT_MESSAGE: {
            let conversation = [...state.conversation];
            //console.log('action.payload==', action.payload)
            const existing = conversation.find(i => i.conversationId === action.payload.conversationId)
            if (existing)
                conversation.push(action.payload)

            let users = [...state.users]
            users.forEach((conv, index) => {
                // debugger
                if (conv._id === action.payload.conversationId) {
                    let messages = [...users[index].messages];
                    messages.push(action.payload);
                    users[index].messages = messages;
                }
            })
            return {
                ...state,
                conversation,
                users
            };
        }
        case SEND_NEW_MEDIA_MESSAGE: {
            return {
                ...state,
                conversation: state.conversation.concat({
                    id: new Date().getTime(),
                    user: state.currentUser,
                    type: 'sent',
                    messageType: 'media',
                    message: 'loading',
                    media: action.payload,
                    sentAt: moment(),
                }),
            };
        }
        case SET_FILTER_DATA: {
            return { ...state, filterData: { ...state.filterData, ...action.payload } };
        }
        case TOGGLE_SIDEBAR_COLLAPSED: {
            return { ...state, isSideBarCollapsed: !state.isSideBarCollapsed };
        }
        case SET_CHAT_LOADER: {
            var chatListInbox = [...state.conversation];
            if (action.payload.value === false) {
                chatListInbox.splice(state.conversation.findIndex(d => d.message === 'uploadingMediaFiles'), 1);
            } else {
                for (var i = 0; i < action.payload.length; i++) {
                    chatListInbox = chatListInbox.concat({
                        message: 'uploadingMediaFiles',
                        senderId: action.payload.id
                    })
                }
            }
            return {
                ...state, conversation: chatListInbox,
                loaderChat: action.payload
            };
        }
        case READ_CONVERSATION: {
            let conversation = [...state.conversation]
            if (state.conversation && state.conversation.length && state.conversation[0].conversationId === action.payload.conversationId) {
                conversation = action.payload.messages;
            }
            let conversations = state.users.map(conv => {
                if (conv._id == action.payload.conversationId) {
                    conv.messages = action.payload.messages;
                }
                return conv;
            })
            return { ...state, users: conversations, conversation: conversation }
        }
        case SET_CHAT_USER_LOADING: {
            return { ...state, loading: action.payload };
        }
        default:
            return state;
    }
};
