import {
    SEND_FORGET_PASSWORD_EMAIL,
    UPDATE_AUTH_USER,
    UPDATE_LOAD_USER,
    SET_USER_REGISTERED,
    SET_USER_INFO
} from '../../@jumbo/constants/ActionTypes';
import axios from "../../services/auth/jwt/config";
import { fetchSuccess } from "./Common";

export const setAuthUser = user => {
    return dispatch => {
        dispatch({
            type: UPDATE_AUTH_USER,
            payload: user,
        });
    };
};
export const setUserInfo = userInfo => {
    return dispatch => {
        dispatch({
            type: SET_USER_INFO,
            payload: userInfo,
        });
    };
};

export const updateLoadUser = loading => {
    return dispatch => {
        dispatch({
            type: UPDATE_LOAD_USER,
            payload: loading,
        });
    };
};

export const setForgetPassMailSent = status => {
    return dispatch => {
        dispatch({
            type: SEND_FORGET_PASSWORD_EMAIL,
            payload: status,
        });
    };
};

export const setUserRegisteredAlert = status => {
    return dispatch => {
        dispatch({
            type: SET_USER_REGISTERED,
            payload: status,
        });
    };
};

export const fetchAndUpdateAuthUser = () => {
    return dispatch => {
        axios.post('auth/me', { isApk: false })
            .then(({ data }) => {
                if (data.user && data.user._id) {
                    dispatch(fetchSuccess());
                    dispatch(setAuthUser({ ...data.user, card: data.card }));
                } else {
                    dispatch(updateLoadUser(true));
                    // localStorage.removeItem('token');
                    // localStorage.removeItem('user');
                    // window.location.reload();
                }
            })
            .catch(function (error) {
                dispatch(updateLoadUser(true));
                console.log(error)
                if (error.response.status == 400) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    window.location.reload();
                }

            });
    }
}
