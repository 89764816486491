import {
    CHANGE_BUDGET_TYPE,
    CHANGE_COMMENT,
    CHANGE_EST_COST,
    FETCH_DRAFT_LEADS_SUCCESS,
    FETCH_LEAD_SUCCESS,
    FETCH_LEADS_FAILURE,
    FETCH_LEADS_REQUEST,
    FETCH_LEADS_SUCCESS,
    HANDLE_COMMENT_CHECKBOX,
    HANDLE_COST_CHECKBOX,
    HANDLE_INFORMATION_CHECKBOX,
    HANDLE_INSPECTION_CHECKBOX,
    POST_QUOTE_REQUEST_END,
    POST_QUOTE_REQUEST_START,
    FETCH_DRAFT_LEADS_FAILURE, FETCH_LEAD_FAILURE,
    FETCH_QUOTED_LEADS_SUCCESS,
    FETCH_QUOTED_LEADS_FAILURE,
    SET_INDEX,
    SET_KEY,
} from "../constants/LeadsActionTypes";

const initialState = {
    loading: false,
    leads: [],
    moreToLoad: true,
    draftLeads: [],
    draftsMoreToLoad: true,
    quotedLeads: [],
    quatedMoreToLoad: true,
    lead: {},
    draftJob: {},
    noOfQuotes: 0,
    error: '',
    PostedJobs_leadd: [],
    PostedJobs_age: 10,
    PostedJobs_age1: '',
    PostedJobs_anchorEl: null,
    PostedJobs_range: [],
    PostedJobs_category: [],
    PostedJobs_value1: [0, 100],
    PostedJobs_perPage: 5,
    quotedLeads_leadd: [],
    quotedLeads_age: 10,
    quotedLeads_age1: '',
    quotedLeads_anchorEl: null,
    quotedLeads_range: [],
    quotedLeads_category: [],
    quotedLeads_value1: [0, 100],
    quotedLeads_perPage: 5,
    draftLeads_leadd: [],
    draftLeads_age: 10,
    draftLeads_age1: '',
    draftLeads_anchorEl: null,
    draftLeads_range: [],
    draftLeads_category: [],
    draftLeads_value1: [0, 100],
    draftLeads_perPage: 5,
    checkbox: {
        cost: false,
        // inspection: false,
        // information: false,
        comment: false
    },
    newQuote: {
        estCost: { type: 'Flat', value: '' },
        comment: { value: '' }
    }
};

export const LeadsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_LEADS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case SET_KEY: {
            return {
                ...state,
                [payload.key]: payload.value,
            };
        }
        case SET_INDEX: {
            return {
                ...state,
                index: payload,
            };
        }
        case FETCH_LEADS_SUCCESS: {
            return {
                ...state,
                loading: false,
                leads: payload.data,
                moreToLoad: payload.moreToLoad,
                error: ''
            }
        }
        case FETCH_DRAFT_LEADS_SUCCESS: {
            return {
                ...state,
                loading: false,
                draftLeads: payload.data,
                draftsMoreToLoad: payload.draftsMoreToLoad,
                error: ''
            }
        }
        case FETCH_QUOTED_LEADS_SUCCESS: {
            return {
                ...state,
                loading: false,
                quotedLeads: payload.data,
                quatedMoreToLoad: payload.quatedMoreToLoad,
                error: ''
            }
        }
        case FETCH_LEADS_FAILURE: {
            return {
                ...state,
                loading: false,
                leads: [],
                error: payload
            }
        }
        case FETCH_DRAFT_LEADS_FAILURE: {
            return {
                ...state,
                loading: false,
                draftLeads: [],
                error: payload
            }
        }
        case FETCH_QUOTED_LEADS_FAILURE: {
            return {
                ...state,
                loading: false,
                quotedLeads: [],
                error: payload
            }
        }
        case FETCH_LEAD_SUCCESS: {
            return {
                ...state,
                lead: payload.job,
                draftJob: payload.draftJob,
                noOfQuotes: payload.noOfQuotes,
            }
        }
        case FETCH_LEAD_FAILURE: {
            return {
                ...state,
                lead: {},
                draftJob: {},
                noOfQuotes: 0,
            }
        }
        case HANDLE_COST_CHECKBOX: {
            return {
                ...state,
                checkbox: { ...state.checkbox, cost: !state.checkbox?.cost }
            }
        }
        case HANDLE_INSPECTION_CHECKBOX: {
            return {
                ...state,
                checkbox: { ...state.checkbox, inspection: !state.checkbox?.inspection }
            }
        }
        case HANDLE_INFORMATION_CHECKBOX: {
            return {
                ...state,
                checkbox: { ...state.checkbox, information: !state.checkbox?.information }
            }
        }
        case HANDLE_COMMENT_CHECKBOX: {
            return {
                ...state,
                checkbox: { ...state.checkbox, comment: !state.checkbox?.comment }
            }
        }
        case CHANGE_BUDGET_TYPE: {
            return {
                ...state,
                newQuote: { ...state.newQuote, estCost: { ...state.newQuote?.estCost, type: payload } }
            }
        }
        case CHANGE_EST_COST: {
            return {
                ...state,
                newQuote: { ...state.newQuote, estCost: { ...state.newQuote?.estCost, value: payload } }
            }
        }
        case CHANGE_COMMENT: {
            return {
                ...state,
                newQuote: { ...state.newQuote, comment: { ...state.newQuote?.comment, value: payload } }
            }
        }
        case POST_QUOTE_REQUEST_START: {
            return {
                ...state,
                loading: true
            }
        }
        case POST_QUOTE_REQUEST_END: {
            return {
                ...state,
                loading: false
            }
        }
        default:
            return state
    }
}
