export const SET_PROFESSIONS = 'SET_PROFESSIONS';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_QUESTION = 'SET_QUESTION';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_JOB = 'SET_JOB';

export const SET_MODAL = 'SET_MODAL';
export const SET_INDEX = 'SET_INDEX';
export const SET_KEY = 'SET_KEY';
export const SET_PWDERROR = 'SET_PWDERROR';
