import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendTextMessage, getChatUsers, updateReadConversation } from '../../../redux/actions/Chat';
import { fetchAndUpdateAuthUser } from '../../../redux/actions/Auth';
import { socketUrl, socketAutoQuoteUrl } from '../../../api-handling/rootUrl';
import { io } from 'socket.io-client';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fetchLeads } from 'redux/actions/LeadsActions';

const socketAutoQuote = io(socketAutoQuoteUrl, {
  transports: ['websocket'],
});

export const socket = io(socketUrl, {
  withCredentials: true,
  forceNew: true,
  reconnectionAttempts: 'Infinity', //avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 10000, //before connect_error and connect_timeout are emitted.
  transports: ['websocket'],
});

socket.on('connect_error', err => {
  console.log(`connect_error due to ${err.message}`);
});

const useStyles = makeStyles(theme => ({
  navCount: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: '0.5px',
    borderRadius: '50%',
    width: 24,
    textAlign: 'center',
    lineHeight: '21.5px',
    position: 'absolute',
    margin: '-13px 0 0 -8px'
  },
}));

const ChatNotification = ({ conversation }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { users, perPage } = useSelector(({ chat }) => chat);
  const dispatch = useDispatch();
  const classes = useStyles();
  const newConversations = () => {
    //debugger
    let newConv = 0;
    if (users && users.length) {
      users.forEach(conv => {
        newConv += conv.messages.filter(i => !i.seenTime && i.senderId !== authUser?._id).length > 0 ? 1 : 0;
      });

      // users.forEach(conv => {
      //   newConv += conv.totalUnSeenMsgCount;
      // });
    }
    return newConv;
  };

  useEffect(() => {
    socket.on('create-message', (message, receiverId, senderid) => {
      //debugger;
      //console.log('message created')
      if (authUser?._id == receiverId || authUser?._id == senderid)
        dispatch(sendTextMessage(message));
    });
    // client-side
    // socket.on('connect', () => {
    //   console.log('socketid', socket.id); // x8WIv7-mJelg7on_ALbx
    // });

    // socket.on('disconnect', () => {
    //   console.log(socket.id); // undefined
    // });
    // socket.on('test', message => {
    //   console.log('message', message);
    // });
    //console.log('Test socket');
    socketAutoQuote.on('new-conversation', (receiverId, senderid) => {
      //console.log('new conversation event from auto quote triggered!');
      if (authUser?._id == receiverId || authUser?._id == senderid)
        dispatch(getChatUsers(authUser?._id, perPage));
    });
    socket.on('update-credits', (senderid) => {
      //console.log('update-credits-triggered');
      if (authUser?._id == senderid)
        dispatch(fetchAndUpdateAuthUser());
    });
    socket.on('new-conversation', (receiverId, senderid) => {
      //console.log('new conversation event triggered!');
      if (authUser?._id == receiverId || authUser?._id == senderid)
        dispatch(getChatUsers(authUser?._id, perPage));
    });
    socket.on('seen-message', data => {
      dispatch(updateReadConversation(data));
    });
    socket.on('fetch-leads', () => {
      //console.log('fetch-leads event triggered!');
      dispatch(fetchLeads(5));
    });
  }, []);
  useEffect(() => {
    dispatch(getChatUsers(authUser?._id, perPage, false));
  }, [perPage])
  return (
    <Box component="span" style={{ marginRight: '0.5rem' }} className={classes.navCount}>
      {newConversations()}
    </Box>
  );
};

export default ChatNotification;
