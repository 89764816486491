import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import FooterLogo from './FooterLogo';
import { Box, Button, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../constants/ThemeOptions';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { Card } from '@material-ui/core';
import { setUserInfo } from '../../../../redux/actions/Auth';
import axios from 'axios';
import { env } from '../../../../key/env';
import { countryCode, countryName } from '../../../../constants/DataBasedOnUrl'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px',
    textAlign: 'center',
  },
}));

const Footer = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { themeType } = useContext(AppContext);
  const date = new Date();
  const history = useHistory();
  const location = useLocation();

  const [locationPopup, selocationPopup] = useState(true);

  let [open, setOpen] = useState(false);
  let [urlIn, setUrlIn] = useState('');
  let [urlTo, setUrlTo] = useState('');
  const { userInfo } = useSelector(({ auth }) => auth);

  const handleClose = () => {
    setIsClicked();
    setOpen(false);
  };
  const countries = {
    // PK: 'tradingseek.co.nz',
    NZ: 'tradingseek.co.nz',
    IN: 'tradingseek.in',
    AU: 'tradingseek.com.au',
  };


  // window.addEventListener('storage', e => {
  //   debugger
  //   if (e.key === 'userInfo') {
  //     debugger
  //     let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  //     setUserInfo(userInfo)
  //   }
  // });

  const key = Object.keys(countries)[Object.values(countries).indexOf(window.location.hostname)];
  function transferDomain(userInfo) {
    setIsClicked();
    window.location.replace(`http://${countries[userInfo?.country.short_name.toUpperCase()]}/`);
  }
  function setIsClicked() {
    var now = new Date();
    var time = now.getTime();
    time += 3600 * 1000;
    //time += 30 * 1000;
    now.setTime(time);
    document.cookie = 'isClicked=' + true + '; expires=' + now.toUTCString() + '; ';
  }
  function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  useEffect(() => {
    if (!userInfo?.country)
      return

    //debugger
    const isCountryExist = Object.keys(countries).includes(userInfo?.country.short_name.toUpperCase());
    let isClicked = getCookie('isClicked');

    // console.log('isCountryExist', isCountryExist);
    // console.log('window.location.hostname', window.location.hostname);
    // console.log('userInfo', userInfo);
    // console.log(countries[userInfo?.country.short_name.toUpperCase()]);
    // console.log('isClicked', isClicked);

    if (isCountryExist && window.location.hostname !== countries[userInfo?.country.short_name.toUpperCase()]) {
      let UrlToArr = countries[userInfo?.country.short_name.toUpperCase()].split('.');
      let UrlToShow = '';
      if (UrlToArr[2]) {
        UrlToShow = 'TradingSeek.' + UrlToArr[1] + '.' + UrlToArr[2];
      } else {
        UrlToShow = 'TradingSeek.' + UrlToArr[1];
      }
      setUrlTo(UrlToShow);
      let UrlInArr = window.location.hostname.split('.');
      let UrlInShow = '';
      if (UrlInArr[2]) {
        UrlInShow = 'TradingSeek.' + UrlInArr[1] + '.' + UrlInArr[2];
      } else {
        UrlInShow = 'TradingSeek.' + UrlInArr[1];
      }
      setUrlIn(UrlInShow);

      const search_param = location.search;
      const string = search_param.slice(1, 14);
      const value = search_param.slice(15);

      if (isClicked == '') {
        if (string == 'locationPopup' && value == 'false') {
        } else {
          let urlArr = window.location.href.split('/');
          let Iframe = urlArr[3] == 'jobiframe';
          //alert(urlArr[3])
          if (!Iframe)
            setOpen(true);
        }
      }
    }
  }, [userInfo]);

  async function setData(latLang) {
    //for testing jamu and kasmir
    //latLang = '34.0857, 74.8055';
    axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLang}&key=${env.googleApiKey}`)
      .then(res => {
        let obj = { country: null, locality: null, postal_code: null };
        for (const result of res.data.results) {
          if (result.address_components) {
            result.address_components.forEach(component => {
              Object.keys(obj).forEach(key => {
                if (component.types.includes(key)) {
                  obj[key] = component;
                }
              });
            });
          }
        }
        delete obj.country;
        // debugger
        // if (!obj.country)
        //   obj.country = { long_name: countryName, short_name: countryCode }

        localStorage.setItem('userInfo', JSON.stringify(obj));
        //let userInfoObj = JSON.stringify(obj)
        //debugger
        dispatch(setUserInfo(obj))
      })
      .catch(error => console.log(error))
      .finally(() => { });
  }

  useEffect(() => {
    if (!userInfo) {
      //debugger
      (async () => {
        axios
          .get('https://ipinfo.io?token=aad467b3abcf85')
          .then(response => {
            //console.log(response.data.loc);
            setData(`${response.data.loc}`);
          })
          .catch(err => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(position => {
                setData(`${position.coords.latitude},${position.coords.longitude}`);
              });
            }
          });
      })();
    }
  }, []);

  return (
    <div>
      <Box className={classes.root} {...props}>
        <Box display="flex" alignItems="center">
          <Hidden xsDown>
            <FooterLogo mr={5} color={themeType === THEME_TYPES.DARK ? 'white' : ''} />
          </Hidden>
          <Box fontSize={{ xs: 12, sm: 14 }} component="p" color="text.disabled" className="mb-0">
            Copyright {process.env.REACT_APP_APP_NAME} © {date.getFullYear()}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <NavLink
            to="/policy/0"
            onClick={() => {
              history.push('/policy/0');
            }}
            className="mr-4">
            Privacy Policy
          </NavLink>
          <NavLink
            to="/policy/1"
            onClick={() => {
              history.push('/policy/1');
            }}
            className="mr-4">
            Terms & Conditions
          </NavLink>
          <NavLink
            to="/policy/2"
            onClick={() => {
              history.push('/policy/2');
            }}
            className="mr-4">
            FAQ`s
          </NavLink>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description">
        <Card style={{ textAlign: 'center', padding: '40px', border: '0px' }}>
          <FooterLogo style={{ alignItems: 'center' }} />
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            You are from {userInfo?.country?.long_name}? Looking for{' '}
            <span style={{ textTransform: 'none', marginLeft: '5px' }}>{urlTo}</span>
          </div>
          <div>
            <Button variant="contained" onClick={handleClose}>
              <img
                src={`https://flagcdn.com/16x12/${key ? key.toLowerCase() : userInfo?.country?.short_name.toLowerCase()}.png`}
                width="20"
                height="15"
                style={{ marginRight: '5px' }}
              />
              Stay On <span style={{ textTransform: 'none', marginLeft: '5px' }}>{urlIn}</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                transferDomain(userInfo);
              }}>
              <img
                src={`https://flagcdn.com/16x12/${userInfo?.country?.short_name.toLowerCase()}.png`}
                width="20"
                height="15"
                style={{ marginRight: '5px' }}
              />
              Go To <span style={{ textTransform: 'none', marginLeft: '5px' }}>{urlTo}</span>
            </Button>
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default Footer;
