import {
  SET_PROFESSIONS,
  SET_QUESTIONS,
  SET_CATEGORY,
  SET_MODAL,
  SELECT_QUESTION,
  SET_INDEX,
  SET_KEY,
  SET_JOB,
  SET_PWDERROR,
} from '../constants/JobActionTypes';

const initialState = {
  isJobModal: false, //flag for post job from 'post a job' button in category carousel
  isPostaJobModal: false,
  loading: false,
  addressAutoCompleteKey: true,
  professions: [],
  profession: null,
  isProfessionsSelected: false,
  address: '',
  autocomplete: null,
  isPhoneVerified: false,
  validPhone: '',
  showCode: false,
  category: null,
  validEmailEntered: false,
  questions: [],
  selectedQuestion: {},
  showModal: false,
  showEmailModal: false,
  emailRequest: false,
  showFirebaseRecaptcha: false,
  index: 0,
  jobs: [],
  hiredjobs: [],
  allProfession: [],
  searchVal: '',
  allPostedJobCategory: [],
  searchProfession: { val: '' },
  searchProfessionval: {},
  searchStatus: { val: '' },
  searchStatusval: {},

  perPage: 6,
  moreToLoad: true,
  value: 0,
  others: {
    'Other (Please specify)': 'textarea',
    'Other (please specify)': 'textarea',
    'Specific date': 'dateSelector',
    Total: 'BudgetInput',
    'Hourly Rate': 'BudgetInput',
  },
  userInfo: {
    email: '',
    isEmailVerified: false,
    new: false,
  },
  isPwdError: false
};

export const JobReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROFESSIONS: {
      return {
        ...state,
        professions: payload,
      };
    }
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: payload,
      };
    }
    case SET_JOB: {
      let newArr = new Array(payload)[0]
      return {
        ...state,
        jobs: newArr,
      };
    }
    case SET_KEY: {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }
    case SELECT_QUESTION: {
      return {
        ...state,
        selectedQuestion: payload,
      };
    }
    case SET_CATEGORY: {
      return {
        ...state,
        category: payload,
      };
    }
    case SET_INDEX: {
      return {
        ...state,
        index: payload,
      };
    }
    case SET_MODAL: {
      return {
        ...state,
        showModal: payload,
      };
    }
    case SET_PWDERROR: {
      return {
        ...state,
        isPwdError: payload,
      };
    }
    default:
      return state;
  }
};
