import {
    CHANGE_QUOTE_PARAMS,
    SET_COLUMNS,
    SET_QUOTE_ACTIVITY_BY_ID,
    SET_QUOTE_ACTIVITY_DATA
} from "../constants/QuoteActivityActionTypes";

const initialState = {
    params: {
        page: 0,
        perPage: 5,
        total: 0,
        status: 'all'
    },
    quotes: [],
    quote: {},
    selectedUser: null,
    open: false,
    columns: [],
};

export const QuoteActivityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CHANGE_QUOTE_PARAMS: {
            return {
                ...state, params: { ...state.params, [payload.key]: payload.value }
            }
        }
        case SET_COLUMNS: {
            return {
                ...state, columns: [...payload]
            }
        }
        case SET_QUOTE_ACTIVITY_DATA: {
            return {
                ...state, quotes: payload.data, params: { ...state.params, total: payload.total }
            }
        }
        case SET_QUOTE_ACTIVITY_BY_ID: {
            return {
                ...state, quote: payload
            }
        }
        default: {
            return state;
        }
    }
}
