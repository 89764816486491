import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar/indexNew';
import { Box, alpha, makeStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { Person, Settings, SyncAlt } from '@material-ui/icons';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';
import { setAuthUser } from '../../../../redux/actions/Auth';
import { http } from '../../../../appmakers/services/http-service';
import { updateBusinessProfile } from '../../../../redux/actions/BusinessProfileActions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { rootUrl } from '../../../../api-handling/rootUrl';
import { fetchError, fetchSuccess } from '../../../../redux/actions';

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
}));

const MySwal = withReactContent(Swal);

const sweetAlerts = dispatch => {
  async function request(dispatch) {
    await http
      .get(`${rootUrl}/delete-account`)
      .then(() => {
        dispatch(fetchSuccess());
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        dispatch(setAuthUser(null));
        MySwal.fire('Deleted', 'Your account is deleted', 'success');
      })
      .catch(err => {
        console.log(err);
      });
  }

  MySwal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true,
  }).then(result => {
    if (result.value) {
      request(dispatch);
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      MySwal.fire('Cancelled', 'Your account is safe :)', 'success');
    }
  });
};

const UserDropDown = () => {
  //console.log('rootUrl', rootUrl);
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  // const [action]
  const dispatch = useDispatch();
  let history = useHistory();
  window.addEventListener('storage', e => {
    if (e.key === 'token' && e.oldValue && !e.newValue) {
      //  http
      // .get(`${rootUrl}/delete-account`)
      // .then(() => {
      //   dispatch(fetchSuccess());
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('user');
      dispatch(setAuthUser(null));
      window.location.reload();
      // MySwal.fire('Deleted', 'Your account is deleted', 'success');
      //})
    }
  });
  var refresh = false;
  localStorage.setItem('isReloadedtradingseek', true);

  window.addEventListener('onbeforeunload', (event) => {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    //debugger;
    if (document.visibilityState === 'hidden') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
    // else {
    //   alert("Window refreshed");
    // }
    // Chrome requires returnValue to be set.
    //event.returnValue = '';

    //return null;
  });
  let actionsList = [];

  const getActionsList = () => {
    if (
      (localStorage.getItem('token') || sessionStorage.getItem('token')) &&
      authUser &&
      authUser.userType === 'Business User' &&
      !authUser.switchedToCustomerView
    ) {
      actionsList = [
        {
          icon: <BusinessCenterIcon />,
          label: 'Business Profile',
        },
        {
          icon: <Settings />,
          label: 'Account Settings',
        },
        {
          icon: <SyncAlt />,
          label: 'Switch to customer view',
        },
        {
          icon: <DeleteIcon />,
          label: 'Delete My Account',
        },
        {
          icon: <ExitToAppIcon />,
          label: 'Logout',
        },
      ];
    } else if (
      (localStorage.getItem('token') || sessionStorage.getItem('token')) &&
      authUser &&
      ((authUser.userType === 'Business User' && authUser.switchedToCustomerView) || authUser.userType === 'Customer User')
    ) {
      actionsList = [
        {
          icon: <Settings />,
          label: 'Settings',
        },
        {
          icon: <DeleteIcon />,
          label: 'Delete My Account',
        },
        {
          icon: <ExitToAppIcon />,
          label: 'Logout',
        },
      ];
      if (authUser.userType === 'Business User') {
        actionsList.unshift({
          icon: <SyncAlt />,
          label: 'Switch to business view',
        });
      } else {
        actionsList[3] = {
          icon: <AppRegistrationIcon />,
          label: 'Register my business'
        }
      }
    }
    return actionsList;
  };

  const onItemClick = item => {
    if (item.label === 'Logout') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    } else if (item.label === 'Settings') {
      history.push('/settings');
    } else if (item.label === 'Business Profile') {
      history.push('/leads/my-business-profile');
    } else if (item.label === 'Account Settings') {
      history.push('/leads/settings');
    } else if (item.label == 'Switch to customer view') {
      dispatch(updateBusinessProfile(true, 'switchedToCustomerView'));
    } else if (item.label == 'Register my business') {
      history.push('/signup-business');
    } else if (item.label == 'Switch to business view') {
      dispatch(updateBusinessProfile(false, 'switchedToCustomerView'));
    } else if (item.label === 'Delete My Account') {
      sweetAlerts(dispatch);
    }
  };
  // console.log('getActionsList = ', getActionsList());
  // console.log('authUser = ', authUser);
  return (
    <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
      <CmtDropdownMenu
        onItemClick={onItemClick}
        TriggerComponent={
          <CmtAvatar alt={authUser ? authUser.userName : ''} src={authUser?.imageUrl} border={'1px solid #667eea'} />
        }
        items={getActionsList()}
      />
    </Box>
  );
};

export default UserDropDown;
