import React, { useImperativeHandle } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  appMainContentWrapper: {
    position: 'relative',
    paddingTop: 30,
    paddingBottom: 30,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  appMainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 'auto',
    // paddingLeft: 15,
    // paddingRight: 15,
    //width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '1%',
      paddingRight: '1%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      paddingLeft: '2%',
      paddingRight: '2%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      paddingLeft: '3%',
      paddingRight: '3%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      paddingLeft: '7.7%',
      paddingRight: '7.7%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      paddingLeft: '7.7%',
      paddingRight: '7.7%',
    },
  },
}));

const CmtContent = React.forwardRef(function LayoutContent(props, ref) {

  const { children } = props;
  const location = useLocation();

  useImperativeHandle(ref, () => ({}));

  const contentRef = React.createRef();
  const classes = useStyles();

  return (
    <Box className={classes.appMainContentWrapper} style={{ padding: location.pathname == '/inbox' ? 0 : '' }} bgcolor="background.main">
      <Box ref={contentRef} className={classes.appMainContent} style={{ padding: location.pathname == '/inbox' ? 0 : '' }}>
        {children}
      </Box>
    </Box>
  );
});

export default CmtContent;
CmtContent.defaultProps = {
  name: 'LayoutContent',
};
