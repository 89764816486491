import {
  FETCH_PROFESSION_REQUEST,
  FETCH_PROFESSION_SUCCESS,
  FETCH_PROFESSION_FAILURE,
  CHANGE_DIF_LOCATION,
  CHANGE_ADDRESS,
} from '../constants/IfameActionType';

const initialState = {
  loading: false,
  serviceDetails: '',
  error: '',
  locationDetails: '',
  address: '',
};

export const IframeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROFESSION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_PROFESSION_SUCCESS: {
      //console.log('payload', payload);
      return {
        ...state,
        loading: false,
        serviceDetails: payload,
        error: '',
      };
    }
    case FETCH_PROFESSION_FAILURE: {
      return {
        ...state,
        loading: false,
        serviceDetails: '',
        error: payload,
      };
    }
    case CHANGE_DIF_LOCATION: {
      return {
        ...state,
        locationDetails: payload,
        error: '',
      };
    }
    case CHANGE_ADDRESS: {
      return {
        ...state,
        address: payload,
        error: '',
      };
    }
    default: {
      return state;
    }
  }
};
