import axios from 'axios';
import { rootUrl } from '../../../api-handling/rootUrl';
import { store } from '../../../App';
// import { AuhMethods } from '../index';
// import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { setAuthUser } from '../../../redux/actions/Auth';

const instance = axios.create({
  baseURL: rootUrl, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

// instance.interceptors.response.use(
//   async (response) => {
//     return response;
//   },
//   (error) => {
//     const currentPath = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
//     debugger
//     if (error.response && error.response.status === 401 && (currentPath !== "login" && currentPath !== "home" && currentPath !== "business-directory" && currentPath !== "about" && currentPath !== "how-it-works" && currentPath !== "forgot-password" && currentPath !== "signup" && currentPath !== "signup-business" && currentPath !== "0" && currentPath !== "1" && currentPath !== "2")) {
//       // store.dispatch(AuhMethods[CurrentAuthMethod].onLogout());
//       localStorage.removeItem('token');
//       sessionStorage.removeItem("token");
//       localStorage.removeItem('user');
//       sessionStorage.removeItem("user");
//       store.dispatch(setAuthUser(null));
//       window.location.href = '/home';
//     }
//     return Promise.reject(error);
//   }

// )
export default instance;
