import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './SweetAlertHelper.css'

const MySwal = withReactContent(Swal);

const sweetAlerts = (text, variant, title = null) => {
    let timerInterval;
    MySwal.fire({
        icon: variant,
        title: title ? title : variant.charAt(0).toUpperCase() + variant.slice(1),
        text: text,
        timer: 3000,
        position: 'top',
        customClass: 'swal-wide',
        timerProgressBar: true,
        showConfirmButton: false,
        onBeforeOpen: () => {
            //Swal.showLoading();
            timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                    const b = content.querySelector('b');
                    if (b) {
                        b.textContent = Swal.getTimerLeft();
                    }
                }
            }, 100);
        },
        onClose: () => {
            clearInterval(timerInterval);
        },
    }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
            // console.log('I was closed by the timer');
        }
    });
};
export const sweetAlertsOkBtn = (text, variant, title = null, closeFunction = null, createBusiness) => {
    MySwal.fire({
        icon: variant,
        title: title ? title : variant.charAt(0).toUpperCase() + variant.slice(1),
        text: text,
        onClose: () => {
            if (createBusiness) {
                window.location.href = '/leads/tab'
            } else {
                if (closeFunction !== null) {
                    closeFunction.push('/home')
                }
            }
        },
    });
}

export default sweetAlerts;
