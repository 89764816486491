import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import SideBar from '../../partials/SideBar';
import CmtHorizontalLayout from '../../../../../@coremat/CmtLayouts/Horizontal';
import CmtHeaderNav from '../../../../../@coremat/CmtLayouts/Horizontal/Header/HeaderNav';
import CmtHeaderMain from '../../../../../@coremat/CmtLayouts/Horizontal/Header/HeaderMain';
import HeaderLogin from '../../partials/Header/HeaderLogin';
import ContentLoader from '../../../ContentLoader';
import CmtHeader from '../../../../../@coremat/CmtLayouts/Horizontal/Header';
import CmtSidebar from '../../../../../@coremat/CmtLayouts/Horizontal/Sidebar';
import CmtContent from '../../../../../@coremat/CmtLayouts/Horizontal/Content';
import MainHeader from './MainHeader';
import useStyles from './index.style';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import CmtFooter from '../../../../../@coremat/CmtLayouts/Horizontal/Footer';
import Footer from '../../partials/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'hooks/useWindowSize';
const HorizontalMinimal = ({ className, children }) => {
  const classes = useStyles();
  const { drawerBreakPoint, sidebarSize, sidebarStyle, showFooter } = useContext(AppContext);
  const { currentUser, conversation } = useSelector(({ chat }) => chat);
  const [display, setDisplay] = useState('block')
  const windowSize = useWindowSize();

  useEffect(() => {
    if (currentUser != null && windowSize.width < 768) {
      setDisplay('none')
    } else {
      setDisplay('block')
    }
  }, [currentUser, windowSize])
  return (
    <CmtHorizontalLayout drawerBreakPoint={drawerBreakPoint} className={clsx('Cmt-horizontalMinimalLayout', className)}>
      <CmtHeader className={classes.appHeaderDark} display={display}>
        {/*<CmtHeaderNav>*/}
        {/*  <HeaderLogin />*/}
        {/*</CmtHeaderNav>*/}
        <CmtHeaderMain>
          <MainHeader />
        </CmtHeaderMain>
      </CmtHeader>
      <CmtSidebar sidebarWidth={sidebarSize} {...sidebarStyle}>
        <SideBar />
      </CmtSidebar>
      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
      {showFooter && (
        <CmtFooter type="static">
          <Footer />
        </CmtFooter>
      )}
    </CmtHorizontalLayout>
  );
};

export default HorizontalMinimal;
