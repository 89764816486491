import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import { setAuthUser } from './Auth';
import sweetAlerts from '../../appmakers/components/SweetAlertHelper';
import { history } from '../store';
import { fetchError, fetchStart, fetchSuccess } from './Common';

export const updateBusinessProfile = (value, field) => {
  // console.log('value=', value, ' field=', field);
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/update-user-field`, { value, field })
      .then(response => {
        dispatch(fetchSuccess());
        // console.log('response', response);
        dispatch(setAuthUser(response.data));
        if (value) {
          history.push('/my-jobs');
          //window.location.href = '/my-jobs';
        } else {
          history.push('/leads/tab');
          //window.location.href = '/leads/tab';
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
        sweetAlerts(error.data, 'error');
      });
  };
};

export const updateBusinessProfileWithoutReload = (value, field) => {
  return dispatch => {
    http
      .put(`${rootUrl}/update-user-field`, { value, field })
      .then(response => {
        // console.log('response', response);
        dispatch(setAuthUser(response.data));
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
      });
  };
};

export const updateBusinessOpeningHours = (openingHours, businessAlwaysOpen) => {
  return dispatch => {
    http
      .put(`${rootUrl}/update-business-hours`, { openingHours, businessAlwaysOpen })
      .then(response => {
        sweetAlerts('User updated', 'success');
        dispatch(setAuthUser(response.data));
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
      });
  };
};
