import React, { useContext, Suspense, lazy, Profiler } from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { Button, Badge, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import LayoutContext from "../../../../../@coremat/CmtLayouts/Horizontal/LayoutContext/LayoutContext";
import { Business, Help, Home, Info, MarkunreadMailbox, PostAdd, RecentActors, Work } from '@material-ui/icons';
import { useSelector } from "react-redux";
import ChatNotification from "../../../notifications/ConversationsNew";
import MailIcon from '@mui/icons-material/Mail';
const UserDropDown = lazy(() => import('../../partials/UserDropDown'));

const useStyles = makeStyles(theme => ({
    perfectScrollbarSidebar: {
        height: '100%',
        transition: 'all 0.3s ease',
        '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
            height: 'calc(100% - 167px)',
        },
        '.Cmt-modernLayout &': {
            height: 'calc(100% - 72px)',
        },
        '.Cmt-miniLayout &': {
            height: 'calc(100% - 91px)',
        },
        '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
            height: 'calc(100% - 167px)',
        },
    },
    root: {
        display: 'flex',
        padding: 0,
        minHeight: 10,
        paddingLeft: '7%',
        paddingRight: '6%',
        [theme.breakpoints.down('md')]: {
            paddingTop: 12,
            paddingBottom: 12,
        },
        '& .Cmt-appIcon': {
            color: theme.palette.text.secondary,
            [theme.breakpoints.down('xs')]: {
                padding: 7,
            },
        },
        '& .Cmt-horizontalNavMenu': {
            position: 'static',
            margin: 'auto 0 auto auto',
            '& .Cmt-navMegaBtn, & > .Cmt-navCollapse > .Cmt-navCollapseBtn': {
                minHeight: 64,
            },
        },
    },
    langRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 2,
            zIndex: 1,
            height: 35,
            width: 1,
        },
    },
    searchIcon: {
        [theme.breakpoints.down('xs')]: {
            padding: 9,
        },
    },
}));

const SideBar = () => {
    const classes = useStyles();
    const { authUser } = useSelector(({ auth }) => auth);
    const { isOpen, setOpen } = useContext(LayoutContext);

    const windowSize = useWindowSize();

    const unAuthenticatedHeaders = [
        {
            name: <IntlMessages id={'sidebar.home'} />,
            icon: <Home />,
            type: 'item',
            link: '/home',
        },
        {
            name: <IntlMessages id={'sidebar.businessDirectory'} />,
            icon: <Business />,
            type: 'item',
            link: '/business-directory',
        },
        {
            name: <IntlMessages id={'sidebar.about'} />,
            icon: <Info />,
            type: 'item',
            link: '/about',
        },
        // {
        //     name: <IntlMessages id={'sidebar.contact'} />,
        //     icon: <RecentActors />,
        //     type: 'item',
        //     link: '/contact',
        // },
        {
            name: <IntlMessages id={'sidebar.howItWorks'} />,
            icon: <Help />,
            type: 'item',
            link: '/how-it-works',
        },
    ];
    const normalUserHeaders = [
        {
            name: <IntlMessages id={'sidebar.myJobs'} />,
            icon: <Work />,
            type: 'item',
            link: '/my-jobs',
        },
        {
            name: <span><IntlMessages id={'sidebar.myResponses'} /><ChatNotification style={{ marginRight: '0.5rem' }} /></span>,
            //name: <IntlMessages id={'sidebar.myResponses'} />,
            icon: <MailIcon sx={{ fontSize: 20 }} />,
            type: 'item',
            link: '/inbox',
        },
        {
            name: <IntlMessages id={'sidebar.help'} />,
            icon: <Help />,
            type: 'item',
            link: '/help',
        },
    ];
    const businessUserHeaders = [
        {
            name: <IntlMessages id={'sidebar.leads'} />,
            icon: <Work />,
            type: 'item',
            link: '/leads/tab',
        },
        {
            name: <span><IntlMessages id={'sidebar.myResponses'} /><ChatNotification style={{ marginRight: '0.5rem' }} /></span>,
            //name: <IntlMessages id={'sidebar.myResponses'} />,
            icon: <MailIcon sx={{ fontSize: 20 }} />,
            type: 'item',
            link: '/inbox',
        },
        {
            name: <IntlMessages id={'sidebar.help'} />,
            icon: <Help />,
            type: 'item',
            link: '/help',
        },
        {
            name: <IntlMessages id={'sidebar.plans'} />,
            icon: <Work />,
            type: 'item',
            link: '/plans',
        },
    ];

    let navigationMenus = unAuthenticatedHeaders;
    if ((localStorage.getItem('token') || sessionStorage.getItem('token')) && ((authUser && authUser.userType === 'Customer User') || (authUser && authUser.userType === 'Business User' && authUser.switchedToCustomerView))) {
        navigationMenus = normalUserHeaders;
    } else if ((localStorage.getItem('token') || sessionStorage.getItem('token')) && (authUser && authUser.userType === 'Business User')) {
        navigationMenus = businessUserHeaders
    }

    return (
        <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
            {authUser && (localStorage.getItem('token') || sessionStorage.getItem('token')) && (
                <>
                    <Box display="flex" alignItems="center" style={{ margin: '10px' }}>
                        {
                            windowSize.width < 700 && <Suspense fallback={'loading...'}>
                                <UserDropDown />
                            </Suspense>
                        }
                        {
                            windowSize.width < 700 && <div>
                                <div className='tooltipmsgsSide'>
                                    <p className='mb-0' >{
                                        (authUser.userName.length > 8) ? authUser.userName.substring(0, 8) + '...' : authUser.userName
                                    }</p>
                                    <span className="tooltiptextmsgsSide"
                                    //style={{ marginLeft: '-43px' }}
                                    >{authUser.userName}</span>
                                </div>
                                <div >
                                    {!authUser?.switchedToCustomerView && authUser?.userType != 'Customer User' &&
                                        <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', display: 'flex' }}>
                                            <div>
                                                Business View
                                            </div>
                                            <Box style={{ marginLeft: '30px' }}>
                                                <Badge badgeContent={`$${authUser.credits || 0}`} color={'primary'} />
                                            </Box>
                                        </div>}
                                    {authUser?.switchedToCustomerView && authUser?.userType != 'Customer User' && <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content' }}>Customer</div>}
                                    {authUser?.customerView && authUser?.userType == 'Customer User' && <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content' }}>Customer</div>}
                                </div>
                            </div>
                        }
                    </Box>
                </>
            )
            }
            <CmtVertical menuItems={navigationMenus} />
            {
                !(localStorage.getItem('token') || sessionStorage.getItem('token')) && (windowSize.width <= 500) && (
                    <>
                        {/* <Hidden xsDown> */}
                        <div>
                            <div style={{ alignItems: 'center', padding: '9px 16px 9px 32px' }}>
                                <Link to={'/login'} style={{ marginRight: '2rem' }} onClick={() => setOpen(false)}>
                                    <a >
                                        Login
                                    </a>
                                </Link>
                                {/* </Hidden> */}
                                <Link to={'/signup-business'} className="Cmt-btn" size="small" onClick={() => setOpen(false)}>
                                    <Button variant="contained" color="primary">
                                        <IntlMessages id="sidebar.appModule.registerBusiness" />
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        </PerfectScrollbar >
    );
};

export default SideBar;
const getNoOfLater = (windowSize) => {
    // if (windowSize.width > 900 && currentUser)
    //   return 15;
    if (windowSize.width <= 350)
        return 23;
    else if (windowSize.width <= 400)
        return 30;
    else if (windowSize.width <= 450)
        return 35;
    else if (windowSize.width <= 500)
        return 40;
    else if (windowSize.width <= 550)
        return 45;
    else if (windowSize.width <= 600)
        return 50;
    else if (windowSize.width <= 650)
        return 55;
    else if (windowSize.width <= 700)
        return 70;
    else if (windowSize.width <= 766)
        return 75;
    else if (windowSize.width < 800)
        return 15;
    else if (windowSize.width < 960)
        return 15;
    else if (windowSize.width >= 960)
        return 23;
};