export const FETCH_PROFESSION_REQUEST = 'fetch_profession_request';
export const FETCH_PROFESSION_SUCCESS = 'fetch_profession_success';
export const FETCH_PROFESSION_FAILURE = 'fetch_profession_failure';

export const CHANGE_DIF_LOCATION = 'change_dif_location';
export const CHANGE_ADDRESS = 'change_address';




