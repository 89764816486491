import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import { ToSeoUrl, encrypt, decrypt } from "../../routes/Pages/Common/commonFunctions";

import {
  CHANGE_BUDGET_TYPE,
  CHANGE_COMMENT,
  CHANGE_EST_COST,
  FETCH_LEAD_FAILURE,
  FETCH_LEAD_REQUEST,
  FETCH_LEAD_SUCCESS,
  FETCH_LEADS_FAILURE,
  FETCH_LEADS_REQUEST,
  FETCH_LEADS_SUCCESS,
  HANDLE_COMMENT_CHECKBOX,
  HANDLE_COST_CHECKBOX,
  HANDLE_INFORMATION_CHECKBOX,
  HANDLE_INSPECTION_CHECKBOX,
  POST_QUOTE_REQUEST_END,
  POST_QUOTE_REQUEST_START,
  FETCH_DRAFT_LEADS_SUCCESS,
  FETCH_DRAFT_LEADS_FAILURE,
  SET_DRAFT_JOB,
  FETCH_QUOTED_LEADS_SUCCESS,
  FETCH_QUOTED_LEADS_FAILURE,
  SET_INDEX,
  SET_KEY,
} from '../constants/LeadsActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import sweetAlerts from '../../appmakers/components/SweetAlertHelper';
import { setAuthUser } from './Auth';

export const fetchLeadsRequest = () => {
  return {
    type: FETCH_LEADS_REQUEST,
  };
};

export const fetchLeadsSuccess = payload => {
  return {
    type: FETCH_LEADS_SUCCESS,
    payload,
  };
};

export const fetchDraftLeadsSuccess = payload => {
  return {
    type: FETCH_DRAFT_LEADS_SUCCESS,
    payload,
  };
};
export const fetchQuotedLeadsSuccess = payload => {
  return {
    type: FETCH_QUOTED_LEADS_SUCCESS,
    payload,
  };
};
export const fetchLeadsFailure = () => {
  return {
    type: FETCH_LEADS_FAILURE,
  };
};
export const fetchDraftLeadsFailure = () => {
  return {
    type: FETCH_DRAFT_LEADS_FAILURE,
  };
};
export const fetchQuotedLeadsFailure = () => {
  return {
    type: FETCH_QUOTED_LEADS_FAILURE,
  };
};
export const fetchLeadRequest = () => {
  return {
    type: FETCH_LEAD_REQUEST,
  };
};
export const fetchLeadSuccess = payload => {
  return {
    type: FETCH_LEAD_SUCCESS,
    payload,
  };
};
export const fetchLeadFailure = () => {
  return {
    type: FETCH_LEAD_FAILURE,
  };
};
export const setKey = payload => {
  return {
    type: SET_KEY,
    payload,
  };
};

export const setIndex = payload => {
  return {
    type: SET_INDEX,
    payload,
  };
};

export const fetchLeads = (perPage, age, age1, range, category) => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(fetchLeadsRequest());
    return http
      .post(`${rootUrl}/get-leads`, {
        params: { perPage, age, age1, range, category },
      })
      .then(response => {
        dispatch(fetchLeadsSuccess(JSON.parse(decrypt(response.data))));
        dispatch(fetchSuccess());
        return response;
      })
      .catch(error => {
        dispatch(fetchLeadsFailure(error.message));
        dispatch(fetchError());
      });
  };
};

export const fetchQuotedLeads = (perPage, age, age1, range, category) => {
  //debugger;
  return dispatch => {
    dispatch(fetchStart());
    dispatch(fetchLeadsRequest());
    return http
      .post(`${rootUrl}/get-quoted-leads`, {
        params: { perPage, age, age1, range, category },
      })
      .then(response => {
        dispatch(fetchQuotedLeadsSuccess(JSON.parse(decrypt(response.data))));
        dispatch(fetchSuccess());
        return response;
      })
      .catch(error => {
        dispatch(fetchQuotedLeadsFailure(error.message));
        dispatch(fetchError());
      });
  };
};

export const fetchDraftJobs = (perPage, age, age1, range, category) => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(fetchLeadsRequest());
    return http
      .post(`${rootUrl}/get-draft-leads`, {
        params: { perPage, age, age1, range, category },
      })
      .then(response => {
        //dispatch(fetchDraftLeadsSuccess(response.data));
        dispatch(fetchDraftLeadsSuccess(JSON.parse(decrypt(response.data))));
        dispatch(fetchSuccess());

      })
      .catch(error => {
        dispatch(fetchDraftLeadsFailure(error.message));
        dispatch(fetchSuccess());

      });
  };
};

export const removeDraftJobs = (jobId) => {
  return dispatch => {
    dispatch(fetchStart());
    return http
      .post(`${rootUrl}/remove-draft-leads`, {
        params: { jobId },
      })
      .then(response => {
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
        return response;
      })
      .catch(error => {
        dispatch(fetchDraftLeadsFailure(error.message));
        dispatch(fetchSuccess());
        sweetAlerts(error.message, 'error');

      });
  };
};
export const fetchLead = id => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(fetchLeadRequest());
    http
      .get(`${rootUrl}/get-lead/${id}`)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch(fetchLeadSuccess(JSON.parse(decrypt(response.data))));
      })
      .catch(error => {
        dispatch(fetchSuccess());
        dispatch(fetchLeadFailure(error.message));
      });
  };
};

export const handleCostCheckbox = () => {
  return {
    type: HANDLE_COST_CHECKBOX,
  };
};
export const handleInspectionCheckbox = () => {
  return {
    type: HANDLE_INSPECTION_CHECKBOX,
  };
};
export const handleInformationCheckbox = () => {
  return {
    type: HANDLE_INFORMATION_CHECKBOX,
  };
};
export const handleCommentCheckbox = () => {
  return {
    type: HANDLE_COMMENT_CHECKBOX,
  };
};
export const changeBudgetType = payload => {
  return {
    type: CHANGE_BUDGET_TYPE,
    payload,
  };
};
export const changeEstCost = payload => {
  return {
    type: CHANGE_EST_COST,
    payload,
  };
};
export const changeComment = payload => {
  return {
    type: CHANGE_COMMENT,
    payload,
  };
};
export const postQuoteRequestStart = () => {
  return {
    type: POST_QUOTE_REQUEST_START,
  };
};
export const postQuoteRequestEnd = () => {
  return {
    type: POST_QUOTE_REQUEST_END,
  };
};

export const postQuote = (payload, history, authUser) => {
  return dispatch => {
    dispatch(fetchStart());
    //console.log('payload', payload);
    dispatch(postQuoteRequestStart());
    http
      .post(`${rootUrl}/send-quote`, {
        data: payload,
      })
      .then(response => {
        //console.log('response after posting Quote', response);
        let user = authUser;
        user.credits = authUser.credits - payload?.lead?.credits;
        dispatch(postQuoteRequestEnd());
        dispatch(setAuthUser(user));
        sweetAlerts(response.data, 'success');
        history.push('/leads/tab');
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(postQuoteRequestEnd());
        console.log('error', error);
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};

export const saveAsDraft = (metadata, id, history) => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(postQuoteRequestStart());
    http
      .post(`${rootUrl}/save-job-as-draft`, {
        id,
        metadata,
      })
      .then(response => {
        dispatch(postQuoteRequestEnd());
        sweetAlerts('Job saved as draft', 'success');
        history.push('/leads/tab');
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(postQuoteRequestEnd());
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());

      });
  };
};
