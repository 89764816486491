import React, { Suspense, lazy, Profiler } from 'react';
import { Badge, Box, Button, darken, alpha, Hidden, makeStyles, Toolbar, Typography } from '@material-ui/core';
// import Logo from '../../partials/Logo';
//import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
//import HeaderMenu from './HeaderMenu';
// import UserDropDown from '../../partials/UserDropDown';
// import IntlMessages from '../../../../utils/IntlMessages';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'hooks/useWindowSize';
const Logo = lazy(() => import('../../partials/Logo'));
const SidebarToggleHandler = lazy(() => import('../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler'));
const HeaderMenu = lazy(() => import('./HeaderMenu'));
const UserDropDown = lazy(() => import('../../partials/UserDropDown'));
const IntlMessages = lazy(() => import('../../../../utils/IntlMessages'));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 0,
    minHeight: 10,
    paddingLeft: '7%',
    paddingRight: '6%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    '& .Cmt-horizontalNavMenu': {
      position: 'static',
      margin: 'auto 0 auto auto',
      '& .Cmt-navMegaBtn, & > .Cmt-navCollapse > .Cmt-navCollapseBtn': {
        minHeight: 64,
      },
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
}));

const MainHeader = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();

  const { authUser } = useSelector(({ auth }) => auth);
  function callback(
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime
  ) {
    //console.log(`${id} took ${actualDuration}ms to render.`);
  }
  return (
    <Suspense fallback={'loading...'}>
      <Toolbar className={classes.root}>
        <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
        <Logo mr={{ xs: 2, sm: 4, lg: 6, xl: 8 }} />
        <Hidden mdDown>
          <Profiler id="Header" onRender={callback}>
            <HeaderMenu />
          </Profiler>
        </Hidden>

        <Box display="flex" alignItems="center" style={{ minWidth: '190px' }} ml={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 14, xl: 14 }} >
          {/*<Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')}>*/}
          {/*  <LanguageSwitcher />*/}
          {/*</Box>*/}
          {/* {(localStorage.getItem('token') || sessionStorage.getItem('token')) &&
          authUser &&
          authUser.userType === 'Business User' &&
          !authUser.switchedToCustomerView && (
            <Box display="flex" style={{ marginRight: '1.5rem', marginTop: '-20px' }}>
              <Badge badgeContent={`$${authUser.credits || 0}`} color={'primary'} />
            </Box>
          )} */}
          {authUser && (localStorage.getItem('token') || sessionStorage.getItem('token')) && (
            <>
              <Box display="flex" alignItems="center" ml="auto">
                {windowSize.width > 700 && <div style={{ color: 'black', justifyContent: 'start', display: 'flex', flexDirection: 'column' }}>
                  <div>
                    {(authUser?.userName?.length > getNoOfLater(windowSize)) ? authUser?.userName.substring(0, getNoOfLater(windowSize)) + '...' : authUser?.userName}
                  </div>
                  {!authUser?.switchedToCustomerView && authUser?.userType != 'Customer User' &&
                    <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', float: 'right' }}>
                      <Box display="flex" style={{ top: '10.5px', right: '30px', position: 'relative' }} >
                        <Badge badgeContent={`$${authUser.credits || 0}`} color={'primary'} overlap="circular" />
                      </Box>
                      <div >
                        Business View
                      </div>
                    </div>}
                  {authUser?.switchedToCustomerView && authUser?.userType != 'Customer User' && <div className="tooltiptextmsgsSide" style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', float: 'right' }}>Customer View</div>}
                  {authUser?.customerView && authUser?.userType == 'Customer User' && <div className="tooltiptextmsgsSide" style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', float: 'right' }}>Customer</div>}
                </div>

                }
                {/* {
                  windowSize.width < 700 && <div>
                    <div className='tooltipmsgsSide'>
                      <p className='mb-0' >{
                        (authUser.userName.length > 8) ? authUser.userName.substring(0, 8) + '...' : authUser.userName
                      }</p>
                      <span className="tooltiptextmsgsSide"
                      //style={{ marginLeft: '-43px' }}
                      >{authUser.userName}</span>
                    </div>
                    <div style={{ width: '100px' }}>
                      {!authUser?.switchedToCustomerView && authUser?.userType != 'Customer User' &&
                        <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', float: 'right' }}>
                          <Box display="flex" style={{ top: '10.5px', right: '30px', position: 'relative' }} >
                            <Badge badgeContent={`$${authUser.credits || 0}`} color={'primary'} />
                          </Box>
                          <div>
                            Business View
                          </div>
                        </div>}
                      {authUser?.switchedToCustomerView && authUser?.userType != 'Customer User' && <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', float: 'right' }}>Customer</div>}
                      {authUser?.customerView && authUser?.userType == 'Customer User' && <div style={{ color: '#667eea', fontWeight: '600', width: 'fit-content', float: 'right' }}>Customer</div>}
                    </div>
                  </div>
                } */}
              </Box>

              {windowSize.width > 700 && <UserDropDown />}
            </>
          )}
          {!(localStorage.getItem('token') || sessionStorage.getItem('token')) && (windowSize.width > 500) && (
            <>
              {/* <Hidden xsDown> */}
              <div style={{ display: 'inline-block' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link to={'/login'} style={{ marginRight: '2rem' }}>
                    <a >
                      Login
                    </a>
                  </Link>
                  {/* </Hidden> */}
                  <Link to={'/signup-business'} className="Cmt-btn" size="small">
                    <Button variant="contained" color="primary">
                      <IntlMessages id="sidebar.appModule.registerBusiness" />
                    </Button>
                  </Link>
                </div>
              </div>
            </>
          )}
        </Box>
      </Toolbar >
    </Suspense>
  );
};

export default MainHeader;
const getNoOfLater = (windowSize) => {
  // if (windowSize.width > 900 && currentUser)
  //   return 15;
  if (windowSize.width <= 350)
    return 23;
  else if (windowSize.width <= 400)
    return 30;
  else if (windowSize.width <= 450)
    return 35;
  else if (windowSize.width <= 500)
    return 40;
  else if (windowSize.width <= 550)
    return 45;
  else if (windowSize.width <= 600)
    return 50;
  else if (windowSize.width <= 650)
    return 55;
  else if (windowSize.width <= 700)
    return 70;
  else if (windowSize.width <= 766)
    return 75;
  else if (windowSize.width < 800)
    return 15;
  else if (windowSize.width < 960)
    return 15;
  else if (windowSize.width >= 960)
    return 23;
};